import restProvider from "ra-data-simple-rest";
import apiUri from "./components/ApiUri";
import { fetchUtils } from "react-admin";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const getMsalToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts || accounts.length === 0) throw new Error("No accounts found");

  const request = {
    scopes: protectedResources.apiHello.scopes,
    account: accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

const httpClient = async (url, options = {}) => {
  const token = await getMsalToken();
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = restProvider(apiUri(), httpClient);

export { dataProvider, getMsalToken };
