const ApiURI = () => {
  let apiUri = "";
  switch (window.location.hostname) {
    case "localhost": {
      apiUri = "https://richter-api-dev.azurewebsites.net";
      break;
    }
    case "127.0.0.1": {
      apiUri = "http://localhost:5000";
      break;
    }
    case "richter.checkurmed.com": {
      apiUri = "https://richter-api-dev.azurewebsites.net";
      break;
    }
    case "richter-dev.checkurmed.com": {
      apiUri = "https://richter-api-dev.azurewebsites.net";
      break;
    }
    case "amstermed.checkurmed.com": {
      apiUri = "https://amstermed-api-dev.azurewebsites.net";
      break;
    }
    case "amstermed-dev.checkurmed.com": {
      apiUri = "https://amstermed-api-dev.azurewebsites.net";
      break;
    }
    case "admin.checkurmed.com": {
      apiUri = "https://checkurmed-api-dev.azurewebsites.net";
      break;
    }
    case "admin-dev.checkurmed.com": {
      apiUri = "https://checkurmed-api-dev.azurewebsites.net";
      break;
    }
    default: {
      apiUri = "https://richter-api-dev.azurewebsites.net";
      break;
    }
  }
  return apiUri;
};

export default ApiURI;
