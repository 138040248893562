import * as React from "react";
import { fetchUtils, Admin, Resource, Login } from "react-admin";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dataProvider } from "./dataProvider";
import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import manufacturers from "./manufacturers";
import products from "./products";
import packages from "./packages";
import scans from "./scans";
import MyLayout from "./MyLayout";
import Dashboard from "./Dashboard";
import Map from "./map/Map";
import customRoutes from "./routes";
import apiUri from "./components/ApiUri";
import {
  ManufacturerIcon,
  PackageIcon,
  ScanIcon,
  ProductIcon,
} from "./images/nav/convertedSVG";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333333",
    },
    secondary: {
      main: "#ffffff",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  shadows: Array(25).fill("none"),
  typography: {
    fontFamily: [
      "Silka",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    RaAppBar: {
      toolbar: {
          '& button': {
              '&:not(:nth-child(1))': {
                  display: 'none'
              }
          }
      }
    },
    MuiButton: {
      root: {
        color: "white",
      },
    },
    MuiMenuButton: {
      root: {
        color: "white",
        padding: "50px",
        background: "red",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "rgba(255,255,255,0.4)",
        padding: "10px",
        height: "80px",
      },
    },
    RaMenuItemLink: {
      active: {
        opacity: "1",
      },
      root: {
        padding: "20px 25px",
        opacity: "0.8",
        textTransform: "capitalize",
        fontSize: "20px",
      },
    },
    RaSidebar: {
      root: {
        zIndex: 100,
        padding: "40px 10px",
      },
    },
    RaLayout: {
      content: {
        padding: "40px !important",
      },
    },
    MuiTableRow: {
      root: {
        minHeight: "60px",
      },
    },
  },
});

const App = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Admin
      dataProvider={dataProvider}
      theme={theme}
      layout={MyLayout}
      dashboard={Map}
      customRoutes={customRoutes}
    >
      {(permissions) => [
        permissions === "admin" ? (
          <Resource
            name="manufacturers"
            icon={ManufacturerIcon}
            {...manufacturers}
          />
        ) : null,
        <Resource name="products" {...products} icon={ProductIcon} />,
        <Resource name="packages" {...packages} icon={PackageIcon} />,
        <Resource name="scans" {...scans} icon={ScanIcon} />,
      ]}
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
